var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_c('PageTitle',[_vm._v("學生")]),_c('hr'),_c('h4',[_vm._v("查詢學生")]),_c('el-form',{staticClass:"search",nativeOn:{"submit":function($event){$event.preventDefault();return _vm.fetchStudents.apply(null, arguments)}}},[_c('el-input',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Please enter name/email"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('el-button',{attrs:{"type":"success","native-type":"submit"}},[_vm._v(" Search ")])],1),_c('hr'),_c('el-table',{attrs:{"data":_vm.students.data}},[_c('el-table-column',{attrs:{"label":"Name","width":"200px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{ name: 'ProfileForAdmin', params: { id: scope.row.id } }}},[_vm._v(" "+_vm._s(_vm.userMethods.displayName(scope.row.first_name, scope.row.last_name))+" ")])]}}])}),_c('el-table-column',{attrs:{"label":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.email)+" "),(scope.row.email_verified_at)?_c('span',{staticClass:"action-icon-without-pointer"},[_c('i',{staticClass:"fas fa-check-circle"})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"考卷數","width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(`${scope.row.user_exam_finished_count}/${scope.row.user_exam_count}`)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"T幣"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"tCoinColumn"},[_vm._v(" "+_vm._s(`${scope.row.point}/${scope.row.point_sum}`)+" "),_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":() => {
              _vm.isGiveTCoin = true;
              _vm.updateTCoinDialogVisible = true;
              _vm.currentUser = scope.row;
            }}},[_c('i',{staticClass:"fas fa-edit"})])],1)]}}])}),_c('el-table-column',{attrs:{"label":"Pro 使用期限","width":"180px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.taken_unlimited_expire_at)?_c('span',[_vm._v(" "+_vm._s(_vm.moment(scope.row.taken_unlimited_expire_at).format("YYYY-MM-DD"))+" ")]):_c('span',[_vm._v(" - ")]),_c('el-button',{attrs:{"type":"success","size":"small"},on:{"click":() => {
            _vm.updateProDialogVisible = true;
            _vm.currentUser = scope.row;
          }}},[_c('i',{staticClass:"fas fa-edit"})]),(scope.row.taken_unlimited_expire_at)?_c('el-button',{attrs:{"type":"danger","size":"small"},on:{"click":() => _vm.removePro(scope.row.id)}},[_c('i',{staticClass:"fas fa-user-minus"})]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"註冊日期","width":"120px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.created_at.split("T")[0])+" ")]}}])})],1),_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next","page-size":15,"current-page":_vm.students.current_page,"total":_vm.students.total},on:{"current-change":_vm.fetchStudents}})],1),_c('el-dialog',{attrs:{"title":"設定 Pro 會員有效日期","visible":_vm.updateProDialogVisible,"width":"400px"},on:{"update:visible":function($event){_vm.updateProDialogVisible=$event}}},[_c('el-date-picker',{staticClass:"datePicker",attrs:{"type":"date","picker-options":_vm.pickerOptions},model:{value:(_vm.newExpiredDate),callback:function ($$v) {_vm.newExpiredDate=$$v},expression:"newExpiredDate"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":() => {
          _vm.updateProDialogVisible = false;
          _vm.currentUser = null;
          _vm.newExpiredDate = null;
        }}},[_vm._v(" "+_vm._s(_vm.$t("alert.cancel"))+" ")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.updateProExpiredDate}},[_vm._v(" "+_vm._s(_vm.$t("alert.confirm"))+" ")])],1)],1),(_vm.currentUser)?_c('el-dialog',{attrs:{"title":"修改使用者T幣","visible":_vm.updateTCoinDialogVisible,"width":"400px"},on:{"update:visible":function($event){_vm.updateTCoinDialogVisible=$event}}},[_c('div',{staticClass:"giveTCoinRadio"},[_c('el-radio-group',{model:{value:(_vm.isGiveTCoin),callback:function ($$v) {_vm.isGiveTCoin=$$v},expression:"isGiveTCoin"}},[_c('el-radio',{attrs:{"label":true}},[_vm._v("儲值")]),_c('el-radio',{attrs:{"label":false}},[_vm._v("退幣")])],1)],1),_c('el-input-number',{staticStyle:{"width":"100%"},attrs:{"min":0,"max":_vm.isGiveTCoin ? Number.MAX_SAFE_INTEGER : _vm.currentUser.point,"step":1,"step-strictly":""},model:{value:(_vm.newTCoin),callback:function ($$v) {_vm.newTCoin=$$v},expression:"newTCoin"}}),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":() => {
          _vm.updateTCoinDialogVisible = false;
          _vm.newTCoin = null;
        }}},[_vm._v(" "+_vm._s(_vm.$t("alert.cancel"))+" ")]),_c('el-button',{attrs:{"type":"success"},on:{"click":_vm.updateTCoin}},[_vm._v(" "+_vm._s(_vm.$t("alert.confirm"))+" ")])],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }